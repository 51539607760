<template>
  <form @submit.prevent="saveDetails">
    <b-collapse class="card">
      <div slot="trigger" slot-scope="props" class="card-header">
        <p class="card-header-title">
          <strong>Details</strong>
        </p>

        <a class="card-header-icon has-text-grey-light">
          <b-icon :icon="props.open ? 'minus' : 'plus'" size="is-small" />
        </a>
      </div>
      <loading v-if="loading" />
      <div v-else class="card-content">
        <site-url-field
          v-if="site"
          :url="$_.get(site, 'url')"
          :disabled="isProcessing"
          required
          locked
          @change="$set(form, 'url', $event.url)"
        />

        <site-software-field
          v-if="site"
          :software="$_.get(site, 'software')"
          :disabled="isProcessing"
          required
          @change="$set(form, 'software', $event)"
        />

        <site-complexity-field
          v-if="$store.getters['user/isAgent']()"
          :complexity="$_.get(site, 'metadata.complexity')"
          :disabled="isProcessing"
          @change="setMetadataProp('complexity', $event)"
        />

        <site-last-audit-field
          v-if="$store.getters['user/isAgent']()"
          :date="
            $_.has(site, 'metadata.lastAuditDate')
              ? site.metadata.lastAuditDate.toDate()
              : null
          "
          :disabled="isProcessing"
          @change="
            setMetadataProp(
              'lastAuditDate',
              $event ? $moment($event).toDate() : null
            )
          "
        />

        <b-field label="Web Hosting Provider">
          <b-input
            :disabled="isProcessing"
            :value="$_.get(site, 'metadata.hostingProvider', '')"
            placeholder="Eg. Fixed"
            @input="setMetadataProp('hostingProvider', $event)"
          />
        </b-field>

        <b-field label="Domain Registrar">
          <b-input
            :disabled="isProcessing"
            :value="$_.get(site, 'metadata.domainRegistrar', '')"
            placeholder="Eg. Google Domains"
            @input="setMetadataProp('domainRegistrar', $event)"
          />
        </b-field>

        <b-field label="DNS Provider">
          <b-input
            :disabled="isProcessing"
            :value="$_.get(site, 'metadata.dnsProvider', '')"
            placeholder="Eg. Cloudflare"
            @input="setMetadataProp('dnsProvider', $event)"
          />
        </b-field>

        <b-field label="Email Provider">
          <b-input
            :disabled="isProcessing"
            :value="$_.get(site, 'metadata.emailProvider', '')"
            placeholder="Eg. GSuite"
            @input="setMetadataProp('emailProvider', $event)"
          />
        </b-field>

        <b-field label="SSL Certificate Provider">
          <b-input
            :disabled="isProcessing"
            :value="$_.get(site, 'metadata.sslProvider', '')"
            placeholder="Eg. DigiCert"
            @input="setMetadataProp('sslProvider', $event)"
          />
        </b-field>

        <button
          v-if="!$_.get(site, 'isDeleted')"
          type="button"
          tabindex="-1"
          class="button is-danger is-outlined"
          @click="deleteSite"
        >
          Delete site
        </button>
      </div>

      <div class="card-footer">
        <div class="is-stretched has-padding-75 has-text-right">
          <button
            :disabled="formIsDisabled"
            :class="{ 'is-loading': isProcessing }"
            type="submit"
            class="button is-success"
          >
            Update
          </button>
        </div>
      </div>
    </b-collapse>
  </form>
</template>

<script>
export default {
  name: "SiteDetails",
  components: {
    "site-url-field": () => import("@shared/sites/_siteUrlField"),
    "site-software-field": () => import("@shared/sites/_siteSoftwareField"),
    "site-complexity-field": () => import("@shared/sites/_siteComplexityField"),
    "site-last-audit-field": () => import("@shared/sites/_siteLastAuditField")
  },
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {},
      formClone: {},
      isProcessing: false,
      loading: true,
      fields: [
        "url",
        "software",
        "metadata.complexity",
        "metadata.lastAuditDate",
        "metadata.hostingProvider",
        "metadata.domainRegistrar",
        "metadata.dnsProvider",
        "metadata.emailProvider",
        "metadata.sslProvider"
      ]
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    formIsValid() {
      if (this.$_.isEqual(this.form, this.formClone)) return false;
      if (this.$_.get(this.form, "metadata.lastAuditDate") === null)
        return false;
      if (!this.form.url) return false;
      if (!this.form.software) return false;
      return true;
    },
    formIsDisabled() {
      return this.isProcessing || !this.formIsValid;
    }
  },
  watch: {
    site() {
      this.$set(this, "formClone", this.getFieldsObject(this.site));
    }
  },
  created() {
    this.$store
      .dispatch("sites/observeSite", {
        siteId: this.siteId
      })
      .finally(() => {
        this.loading = false;
        this.$set(this, "form", this.getFieldsObject(this.site));
        this.$set(this, "formClone", this.$_.cloneDeep(this.form));
      });
  },
  beforeDestroy() {
    this.$store.dispatch("sites/unobserveSite", {
      siteId: this.siteId
    });
  },
  methods: {
    getFieldsObject(site) {
      const settings = {
        ...this.$_.pick(site, this.fields)
      };

      if (this.$_.has(settings, "metadata.lastAuditDate")) {
        this.$_.set(
          settings,
          "metadata.lastAuditDate",
          settings.metadata.lastAuditDate.toDate()
        );
      }
      return settings;
    },
    setMetadataProp(prop, value) {
      if (!this.$_.has(this.form, "metadata"))
        this.$set(this.form, "metadata", {});
      this.$set(this.form.metadata, prop, value || null);

      if (
        this.$_.isEmpty(value) &&
        !this.$_.isDate(value) &&
        !this.$_.has(this.formClone, `metadata.${prop}`)
      )
        this.$delete(this.form.metadata, prop);
    },
    saveDetails() {
      if (this.formIsValid && !this.isProcessing) {
        this.isProcessing = true;
        this.$store
          .dispatch("sites/updateSite", {
            siteId: this.siteId,
            payload: this.form
          })
          .then(() => {
            this.$toast.open({
              message: "Site details updated"
            });
          })
          .catch(error => {
            this.$toast.open({
              message: error.message,
              type: "is-danger"
            });
          })
          .finally(() => {
            this.isProcessing = false;
          });
      }
    },
    deleteSite() {
      this.$store
        .dispatch("sites/deleteSite", { siteId: this.siteId })
        .then(result => {
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
